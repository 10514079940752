import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import PropTypes from "prop-types";
import Icon from "react-icons-kit";
import Fade from "react-reveal/Fade";
import Box from "reusecore/src/elements/Box";
import Text from "reusecore/src/elements/Text";
import Heading from "reusecore/src/elements/Heading";
import Button from "reusecore/src/elements/Button";
import Container from "common/src/components/UI/Container";
import TiltShape from "../TiltShape";
import Hero from "../../../../../common/src/assets/image/saasModern/hero.svg";
import { PolicyWrapper, DiscountWrapper, DiscountLabel } from "./policy.style";
import { ic_play_circle_filled } from "react-icons-kit/md/ic_play_circle_filled";

const PolicySection = ({
  row,
  contentWrapper,
  title,
  description,
  imageWrapper,
  buttonWrapper,
  fillButton
}) => {
  return (
    <PolicyWrapper id="banner_section">
      <Container>
        <Box {...row} style={{ textAlign: "left" }}>
          <Text
            {...description}
            content="
            This privacy notice discloses the privacy practices for
            calendarbranch.com. This privacy notice applies solely to
            information collected by this website. It will notify you of the
            following:"
          />
          <ol style={{ color: "#fff" }}>
            <li style={{ listStyle: "inherit" }}>
              What personally identifiable information is collected from you
              through the website, how it is used and with whom it may be
              shared.
            </li>
            <li style={{ listStyle: "inherit" }}>
              What choices are available to you regarding the use of your data.
            </li>
            <li style={{ listStyle: "inherit" }}>
              The security procedures in place to protect the misuse of your
              information.
            </li>
            <li style={{ listStyle: "inherit" }}>
              How you can correct any inaccuracies in the information.
            </li>
          </ol>

          <h3 style={{ color: "#fff" }}>Collection, Use, and Sharing</h3>
          <Text
            {...description}
            content="
            We are the sole owners of the information collected on this site. We
            only have access to/collect information that you voluntarily give us
            via email, while registering with gmail or other direct contact from
            you. We will not sell or rent this information to anyone."
          />
          <Text
            {...description}
            content="We will use your information to respond to you, regarding the reason
            you contacted us. We remain the right to share your information with
            any third party outside of our organization, other than as necessary
            to fulfill your request, generate personalized reports, or other
            tasks related to this service."
          />

          <Text
            {...description}
            content="
            Unless you ask us not to, we may contact you via email in the future
            to tell you about specials, new products or services, or changes to
            this privacy policy.
          "
          />

          <h3 style={{ color: "#fff" }}>Your Access to and Control Over</h3>
          <Text
            {...description}
            content="
            Information You may opt out of any future contacts from us at any
            time. You can do the following at any time by contacting us via the
            email address given on our website:
          "
          />
          <ol style={{ color: "#fff", width: "100%" }}>
            <li style={{ listStyle: "inherit" }}>
              See what data we have about you, if any.
            </li>
            <li style={{ listStyle: "inherit" }}>
              Change/correct any data we have about you.
            </li>
            <li style={{ listStyle: "inherit" }}>
              Have us delete any data we have about you.
            </li>
            <li style={{ listStyle: "inherit" }}>
              Express any concern you have about our use of your data.
            </li>
          </ol>

          <h3 style={{ color: "#fff" }}>Security</h3>
          <Text
            {...description}
            content="
            We take precautions to protect your information. When you submit
            sensitive information via the website, your information is protected
            both online and offline.
          "
          />
          <Text
            {...description}
            content="
            Wherever we collect sensitive information (such as credit card data
            or calendar events), that information is encrypted and transmitted
            to us in a secure way. You can verify this by looking for a lock
            icon in the address bar and looking for `https` at the beginning of
            the address of the Web page."
          />
          <Text
            {...description}
            content="
            While we use encryption to protect sensitive information transmitted
            online, we also protect your information offline. Only employees who
            need the information to perform a specific job (for example, billing
            or customer service) are granted access to personally identifiable
            information. The computers/servers in which we store personally
            identifiable information are kept in a secure environment.
          "
          />

          <Text
            {...description}
            content="
            If you feel that we are not abiding by this privacy policy, you
            should contact us immediately via email info@calendarbranch.com.
          "
          />
        </Box>
      </Container>
    </PolicyWrapper>
  );
};

PolicySection.propTypes = {
  row: PropTypes.object,
  contentWrapper: PropTypes.object,
  discountAmount: PropTypes.object,
  discountText: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  imageWrapper: PropTypes.object,
  buttonWrapper: PropTypes.object,
  button: PropTypes.object,
  fillButton: PropTypes.object
};

PolicySection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center"
  },
  contentWrapper: {
    width: ["100%", "100%", "80%", "55%", "50%"],
    mb: "40px"
  },
  imageWrapper: {
    width: "100%"
  },
  title: {
    fontSize: ["24px", "32px", "40px", "42px", "46px"],
    fontWeight: "700",
    color: "#fff",
    letterSpacing: "-0.025em",
    mb: ["20px", "25px", "25px", "25px", "25px"],
    lineHeight: "1.2",
    textAlign: "center"
  },
  description: {
    fontSize: ["15px", "16px", "16px", "16px", "16px"],
    color: "#fff",
    lineHeight: "1.75",
    mb: "0",
    textAlign: "center"
  },
  discountAmount: {
    fontSize: ["13px", "14px", "14px", "14px", "14px"],
    fontWeight: "700",
    color: "#00865b",
    mb: 0,
    as: "span",
    mr: "0.4em"
  },
  discountText: {
    fontSize: ["13px", "14px", "14px", "14px", "14px"],
    fontWeight: "700",
    color: "#fff",
    mb: 0,
    as: "span"
  },
  fillButton: {
    type: "button",
    fontSize: ["13px", "14px"],
    fontWeight: "600",
    borderRadius: "4px",
    p: ["0px 15px", "8px 22px"],
    colors: "secondaryWithBg",
    minWidth: ["auto", "150px"],
    height: ["40px", "46px"],
    minHeight: "auto"
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: "center",
    mt: "35px"
  },
  button: {
    type: "button",
    fontSize: ["13px", "14px"],
    fontWeight: "600",
    borderRadius: "4px",
    p: ["0px 15px", "8px 22px"],
    color: "#fff",
    colors: "secondary",
    height: ["40px", "46px"],
    minHeight: "auto"
  }
};

export default PolicySection;
